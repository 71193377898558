.wrapper {
  background: #1b1b1b;
  padding: 15px 0;
  position: fixed;
  width: 100%;
}
.container {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 15px;
}
.logo {
  width: 70px;
}
.infoBox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
}
.info {
  display: flex;
  align-items: center;
  gap: 7px;
}
.icon {
  font-size: 18px;
  color: #1ee0ac;
}
.text {
  color: #fff;
  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
}
.menuContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  position: relative;
}
.menu {
  color: #fff;
  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}
.arrow {
  color: #fff;
  font-size: 18px;
}
.dropDowns {
  position: absolute;
  top: calc(100% + 10px);
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #000;
  z-index: 5;
  border-radius: 5px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 100px;
  padding: 4px 5px;
}
.menuItem {
  color: #fff;
  font-family: Open Sans;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 170%;
  text-transform: capitalize;
}
@media only screen and (max-width: 767px) {
  .container {
    grid-template-columns: 1fr auto;
    gap: 0px;
  }
  .infoBox {
    order: 3;
    grid-column: 1/-1;
  }
  .menuContainer {
    order: 2;
  }
}
@media only screen and (max-width: 520px) {
  .logo {
    width: 70px;
  }
  .infoBox {
    flex-direction: column;
    gap: 0px;
  }
  .icon {
    font-size: 16px;
  }
  .text,
  .menu,
  .menuItem {
    font-size: 14px;
  }
}
