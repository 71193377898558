.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.heading {
  color: #fafafa;
  font-size: 35px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -1.2px;
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.button {
  text-align: center;
  font-size: 14px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.26px;
  border-radius: 4px;
  padding: 10px 24px;
  border: none;
  outline: none;
  cursor: pointer;
}

.sendFunds {
  color: #fafafa;
  background: #2a2a2a;
}

.investAndEarn {
  color: #2a2a2a;

  background: #fafafa;
}

.deposit {
  color: #2a2a2a;

  background: #1ee0ac;
}

.text {
  color: #a1a1a1;
  font-size: 13px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  padding-top: 8px;
}

@media only screen and (max-width: 520px) {
  .button {
    padding: 10px 18px;
  }
}