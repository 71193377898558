.makeYourPayment {
  max-width: 486px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.wrapper {
  border: 1px solid #39393c;
  background: #2a2a2a;
}

.title {
  color: #fafafa;
  text-align: center;
  font-size: 23.4px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.792px;
  padding-bottom: 8px;
}

.text {
  color: rgba(161, 161, 161, 0.8);
  text-align: center;
  font-size: 15px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  letter-spacing: -0.16px;
}

.hightlight {
  color: #1ee0ac;
}

.wrapper {
  border-radius: 4px;
  border: 1px solid #39393c;
  background: #2a2a2a;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #39393c;
  padding: 24px;
  width: 100%;
}

.heading {
  color: #fafafa;
  font-size: 15.8px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 18.48px;
  letter-spacing: -0.168px;
}

.expireIn {
  color: #a1a1a1;
  text-align: right;
  font-size: 14px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.mainBox {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.qrCode {
  width: 100px;
  height: 100px;
}

.amount,
.balance {
  color: #fafafa;
  text-align: center;
  font-size: 19px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.2px;
}

.balance {
  font-size: 14px;
}

.addressText {
  color: #a1a1a1;
  text-align: center;
  font-size: 13px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 1.95px;
  text-transform: uppercase;
}

.address {
  width: 100%;
  padding: 10px 18px;
  border-radius: 5px;
  border: 1px solid #39393c;
  background: #2a2a2a;
  color: #2d7ae6;
  font-size: 14px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  word-break: break-all;
}

.highlight {
  color: #1ee0ac;
}

.currency {
  text-transform: uppercase;
}

.note {
  color: #a1a1a1;
  font-size: 12px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  text-align: left;
  width: 100%;
}

.bottomBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  align-items: center;
  border-top: 1px solid #39393c;
  padding: 24px;
  width: 100%;
  background: rgba(31, 43, 58, 0.3);
}

.warning {
  color: #ff5151;
  text-align: center;
  font-size: 12px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;

  line-height: 150%;
}

.confirmation {
  color: #a1a1a1;
  text-align: center;
  font-size: 12px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cancelOrder {
  color: #ff5151;
  font-size: 11.6px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 13.6px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

.backToDashboard {
  color: #1ee0ac;
  font-size: 11.6px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 13.6px;
  border: none;
  outline: none;
  background: none;
  padding: 7px 0;
  cursor: pointer;
}

@media only screen and (max-width: 520px) {

  .header,
  .mainBox,
  .bottomBox {
    padding: 18px;
  }
}