.wrapper {
  /* display: grid; */
  /* grid-template-columns: 1fr 263px;
  gap: 18px; */
  padding-right: calc(263px + 18px);
  position: relative;
}
.rightSide {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.forMobileAndTab {
  display: none;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding: 0;
  }
  .rightSide {
    display: none;
  }
  .forMobileAndTab {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 18px;
  }
}
@media only screen and (max-width: 520px) {
  .forMobileAndTab {
    grid-template-columns: 1fr;
  }
}
