.wrapper {
  padding: 50px 0;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 50px;
}
.heading {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}
.subHeading {
  color: #fff;
  text-align: center;
  font-family: Asap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.line {
  width: 54px;
  height: 4px;
  background: #1ee0ac;
}
.slider {
  padding: 0 20px;
}
.swiperBox {
  padding: 0 10px;
}
.card {
  border-radius: 12px;
  background: #2a2a2a;
  box-shadow: 0px 12px 10px 0px rgba(30, 29, 29, 0.07);
  padding-bottom: 12px;
}
.image {
  width: 100%;

  display: block;
  border-radius: 12px 12px 0 0;
}
.details {
  padding: 12px 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.title {
  color: #1ee0ac;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.info {
  color: #fff;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}
.seeMore {
  color: #1ee0ac;
  cursor: pointer;
}
.nextButton,
.previousButton {
  border: none;
  cursor: pointer;

  position: absolute;

  z-index: 500;
  border: 1px solid #ced1d8;
  top: 40%;
  background: transparent;
  color: #fff;
  border: none;
  transform: translateY(-50%);
}
.previousButton {
  left: -30px;
}
.nextButton {
  right: -30px;
}
.previousButton .arrow {
  transform: rotate(-180deg);
}
.arrow {
  color: #1ee0ac;
  font-size: 30px;
}
.activeButton {
  color: #fff;
  border: none;
}

@media only screen and (max-width: 991px) {
  .heading {
    font-size: 36px;
  }
}
@media only screen and (max-width: 767px) {
  .nextButton {
    right: -40px;
  }
}
@media only screen and (max-width: 520px) {
  .slider {
    padding: 0px;
  }
  .title {
    font-size: 16px;
  }
  .info {
    font-size: 14px;
  }
}
