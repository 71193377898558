.wrapper {
  background: url(../../../images/home1/heroImg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  padding-top: calc(84px + 50px);
  padding-bottom: 15px;
}

.textContainer {
  max-width: 665px;
  width: 100%;

  padding-bottom: 70px;
}
.heading {
  color: #1ee0ac;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
}
.title {
  color: #fff;
  font-family: Montserrat;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

.tagline {
  color: #1ee0ac;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 255%;
}
.text {
  color: #fff;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.button {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 106.25%;
  border-radius: 30px;
  background: #1ee0ac;
  padding: 15px 24px;
  display: inline-block;
  margin-top: 18px;
}
.socialContainer {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: auto;
  justify-self: flex-end;
}
.icon {
  color: #ffffff;
  font-size: 24px;
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding-top: calc(109.5px + 30px);
  }
  .textContainer {
    padding-bottom: 50px;
  }
  .heading {
    font-size: 36px;
  }
  .title {
    font-size: 48px;
  }
  .text {
    font-size: 18px;
  }
  .br {
    display: none;
  }
}
@media only screen and (max-width: 520px) {
  .wrapper {
    padding-top: calc(124.967px + 20px);
  }
  .heading {
    font-size: 28px;
  }
  .textContainer {
    padding-bottom: 40px;
  }
  .title {
    line-height: 105%;
    font-size: 40px;
    padding-bottom: 5px;
  }
  .text {
    font-size: 16px;
  }
  .button {
    font-size: 16px;
    padding: 12px 24px;
  }
}
