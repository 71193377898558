.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  gap: 12px;
  padding: 0 15px;
}

.value {
  color: #fff;
  text-align: center;
  font-family: Fira Code;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  opacity: 0.3;
}

.arrow {
  cursor: pointer;
  font-size: 14px;
}

.chart {
  margin-right: -15px;
  margin-left: -15px;
  position: relative;
}

.tabContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.durationContainer {
  margin-left: auto;
}

.tab {
  color: #fff;
  text-align: center;
  font-family: Fira Code;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  padding: 8px 6px;
}

.activeTab {
  border-radius: 1px;
  background: #1ee0ac;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
}

.myChart {
  position: relative;
  z-index: -1;
}