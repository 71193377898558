.dropdown {
  position: relative;
  padding-top: 20px;
}
.tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 12px;
  border: 1px solid #39393c;
  width: 100%;
}
.dropdownItems {
  position: absolute;
  z-index: 10000000;
  top: calc(100% + 10px);
  left: 0;

  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: #1b1b1b;
  padding: 10px 12px;
  border: 1px solid #39393c;
}
.item,
.tab {
  cursor: pointer;
  color: #a1a1a1;
  font-size: 14px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@media only screen and (max-width: 520px) {
  .item,
  .tab {
    font-size: 14px;
  }
}
