.mainWrapper {
  border: 1px solid #39393c;
  background: #2a2a2a;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.15);
  max-width: 520px;
  width: 95%;
  margin: 0 auti;
  position: absolute;
  top: 100px;
  left: 50%;

  transform: translateX(-50%);
  z-index: 11;
}

.wrapper {
  border-radius: 4px;

  padding: 35px;

  display: flex;
  flex-direction: column;
  gap: 15px;
}

.title {
  color: #fafafa;
  font-size: 23px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.48px;
  padding-bottom: 15px;
}

.text {
  color: #a1a1a1;
  font-size: 14px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.form {
  display: flex;
  flex-direction: column;
}

.rememberOrForgot {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
}

.termsAndCondition {
  justify-content: flex-start;
  gap: 4px;
}

.forgot {
  color: #1ee0ac;
  font-size: 11.6px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 13.6px;
  padding-top: 15px;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 25px;
}

.button {
  text-align: center;
  font-size: 15px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.26px;
  border-radius: 4px;
  padding: 12px 24px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fafafa;
  background: transparent;
}

.transferButton {
  color: #2a2a2a;
  background: #1ee0ac;
}

.suggestionContainer {
  width: 100%;
  border-top: 1px solid #39393b;
  padding: 30px 35px;
}

.suggestion {
  color: #1ee0ac;
  font-size: 12px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  max-width: 381px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  background: rgba(34, 34, 34, 0.6);
  backdrop-filter: blur(10px);
  z-index: 10;
  width: 100%;
  min-height: 100vh;
  cursor: pointer;
}

@media only screen and (max-width: 520px) {
  .mainWrapper {
    top: 50px;
  }

  .wrapper {
    padding: 18px 12px;
    gap: 18px;
  }

  .suggestionContainer {
    padding: 18px 12px;
  }
}

@media only screen and (max-width: 399px) {
  .termsAndCondition {
    font-size: 11px;
  }

  .termsAndCondition {
    padding-top: 10px;
  }
}