.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  background: url(../../../images/rewards.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 263px;
  border-bottom: 2px solid #1ee0ac;
  position: relative;
  padding: 20px;
  height: 100%;
}

.wrapper::before {
  content: " ";
  position: absolute;
  background-color: rgb(253, 121, 19, 0.05);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.title {
  color: #fafafa;
  text-align: center;
  font-family: Fira Code;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 136.364%;
  padding-bottom: 20px;
}

.value {
  color: #fafafa;
  text-align: center;
  font-family: Fira Code;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 109.091%;
}

.usdValue {
  color: rgba(250, 250, 250, 0.3);
  text-align: center;
  font-family: Fira Code;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.333%;
}

.button {
  color: #000;
  text-align: center;
  font-family: Fira Code;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 111.111%;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  border-radius: 6px;
  border: none;
  outline: none;
  cursor: pointer;
  background: #1ee0ac;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 10px 18px;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .wrapper {
    width: 100%;
  }
}