.dropdown {
  position: relative;
}
.tab {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dropdownItems {
  position: absolute;
  z-index: 10000000;
  top: calc(100% + 0px);
  left: 50%;
  transform: translateX(-50%);
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: #1b1b1b;
  padding: 8px;
}
.item {
  cursor: pointer;
}
