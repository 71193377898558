.wrapper {
  border-radius: 4px;
  border: 1px solid #39393c;
  background: #2a2a2a;

  padding: 24px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.amountContainer {
  display: flex;
  align-items: center;
  gap: 50px;
}

.amount {
  color: #1ee0ac;
  font-size: 26.4px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.currency {
  color: #fafafa;
}

.text,
.secondaryText,
.value {
  color: #a1a1a1;
  font-size: 14px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
}

.totalReturned {
  min-width: 280px;
  margin-left: auto;
}

.secondaryText {
  color: #fafafa;
}

.value {
  color: #1ee0ac;
}

.secondaryText,
.value {
  margin-left: auto;
}

.boxWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: 0px 32px;
}

.spaceBetween {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;
  border-bottom: 1px solid #39393b;
  padding: 5px 0;
}

@media only screen and (max-width: 1500px) {
  .boxWrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 991px) {
  .boxWrapper {
    gap: 0 24px;
  }
}

@media only screen and (max-width: 830px) {
  .boxWrapper {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 767px) {
  .amountContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .totalReturned {
    min-width: auto;
  }
}

@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 16px;
  }

  .amount {
    font-size: 22px;
  }

  .totalReturned {
    margin: 0;
  }
}