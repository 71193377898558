.wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.text {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;

  color: #2a2a2a;
}

.switchCheckbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switchLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 35px;
  height: 20px;
  background: #1ee0ac;
  border-radius: 15px;
  position: relative;
  transition: background-color 0.2s;
}

.switchLabel .switchButton {
  content: "";
  position: absolute;
  top: 4px;
  left: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: 0.2s;
  background: #2a2a2a;
  box-shadow: 0px 0px 3.68182px rgba(38, 44, 71, 0.16);
}

.switchLabel .switchButton2 {
  content: "";
  position: absolute;
  top: 6px;
  left: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: 0.2s;
  background: #2a2a2a;
  box-shadow: 0px 0px 3.68182px rgba(38, 44, 71, 0.16);
}

.switchCheckbox:checked+.switchLabel .switchButton {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

/* .switchLabel:active .switchButton {
    width: px;
  } */
@media only screen and (max-width: 767px) {
  .wrapper {
    margin-left: -10px;
  }
}